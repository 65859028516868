@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  display: none;
}

.scroll-section {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  transition: opacity 0.3s ease-out;
}

.content-section {
  position: relative;
  z-index: 10;
  background: transparent;
}

.background-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  will-change: transform;
  transform: translateZ(0);
}

/* Stars */
.dot {
  @apply bg-white rounded-full;
}

.sparkle {
  position: absolute;
  width: 0.5rem;
  height: 0.5rem;
  background: radial-gradient(circle, currentColor 0%, transparent 60%);
  border-radius: 50%;
}

.starburst {
  position: absolute;
  width: 0.5rem;
  height: 0.5rem;
  background: radial-gradient(circle, currentColor 0%, transparent 70%);
}

.starburst::before,
.starburst::after {
  content: '';
  position: absolute;
  background: radial-gradient(circle, currentColor 0%, transparent 70%);
  width: 0.5rem;
  height: 0.5rem;
  left: 0;
  top: 0;
}

.starburst::before {
  transform: rotate(45deg);
}

.starburst::after {
  transform: rotate(90deg);
}

.glow {
  filter: drop-shadow(0 0 0.5rem currentColor);
}

.ai-star {
  position: relative;
  width: 110.05px;
  height: 110.42px;
}

/* Mobile Optimizations */
@media screen and (max-width: 768px) {
  .content-section {
    padding: 1rem;
  }

  .scroll-section {
    height: 100dvh; /* Use dynamic viewport height for mobile */
  }

  /* Adjust star sizes for mobile */
  .ai-star {
    width: 80px;
    height: 80px;
  }

  /* Optimize sparkle size for mobile */
  .sparkle {
    width: 0.3rem;
    height: 0.3rem;
  }

  .starburst {
    width: 0.3rem;
    height: 0.3rem;
  }
}
